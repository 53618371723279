import logo from './logo.svg';
import Profile from './images/faucet-profile-img-1.jpeg';
import networkLogo from './images/network_logo.png'
import './App.css';
import { useEffect, useState, useCallback } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Web3 from 'web3';
import socketIO from 'socket.io-client';
import { GoogleReCaptchaProvider, GoogleReCaptcha } from 'react-google-recaptcha-v3';

const socket = socketIO.connect('wss://faucetws.utclife.com');

function App() {
  const web3 = new Web3(new Web3.providers.HttpProvider('https://utctestrpc.utclife.com/'));

  console.log("web3Value:", web3)
  const [address, setaddress] = useState();
  const [blockNumber, setblockNumber] = useState(0);
  const [TotalAmount, setTotalAmount] = useState(0);
  const [FoundedCount, setFoundedCount] = useState(0);
  const [peerCount, setpeerCount] = useState(0);
  const [responseaddress, setResponseaddress] = useState([]);
  const [responseText, setResponseText] = useState("A Few Seconds ago");
  const [siteKey, setSiteKey] = useState("");
  const [captchaToken, setCaptchaToken] = useState("");

  useEffect(() => {
    socket.on("recaptcha-keys", function (details) {
      console.log("Secret Key:", details);
      setSiteKey(details.Sitekey);
    });
    socket.emit("recaptcha-keys");
  }, [])
  console.log("siteKeyssss:", siteKey);
  const handleVerify = useCallback((token) => {
    setCaptchaToken(token);
  }, [])

  useEffect(() => {
    socket.on("requested", function (from) {
      // console.log(from);
    });
    socket.on("Processing", function (from) {
      setResponseaddress([...responseaddress, { Address: from.address, Loading: 0, _status: "A few seconds ago" }]);
      // console.log(from);
    });
    socket.on("Success", function (from) {
      setResponseaddress([...responseaddress, { Address: from.address, Loading: 0, _status: "Funded" }]);
      setInterval(() => {
        changeID(from.address);
        window.location.reload();
      }, 3000)

    });

    socket.on("Failed", function (from) {
      console.log("failed");
      failed(from.dataResponse);
    });
    return () => {
      // socket.off("recaptcha-keys");
      socket.off('Failed');
      socket.off('requested');
      socket.off('Success');
      socket.off('send-faucet');
    }

  }, [socket]);


  const requestAddress = async () => {
    if (address === undefined || address === "") {
      failed("Please enter Wallet address");
    }

    // Emit the request along with the reCAPTCHA token
    socket.emit('request', {
      address,
      captchaToken
    });
  }

  useEffect(() => {
    socket.on("send-faucet", function (details) {
      setblockNumber(details.blockNumber);
      setTotalAmount(details.totalAmount);
      setFoundedCount(details.countFounded);
      setpeerCount(details.peerCount);
    });
    socket.emit("get-faucet");
  }, [])
  useEffect(() => {

    let intervalWidth = 1;
    responseaddress?.map((card, index) => {
      setInterval(() => {
        let classadd = "width" + (intervalWidth * 10);
        // console.log(card.Address)
        if (document.getElementById(card.Address)) {
          document.getElementById(card.Address).classList.add(classadd);
          intervalWidth++;
        }
      }, 1000);
    })

  })

  const _setaddress = (e) => {
    e.preventDefault();
    setaddress(e.target.value);
  }

  const success = (message) => {
    toast.success(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const failed = (message) => {
    console.log(responseaddress);
    toast.error(message, {
      position: toast.POSITION.TOP_CENTER,
    });
  };
  const changeID = (address) => {

    setResponseaddress(responseaddress.filter(item => item !== address));
  }

  return (
    <>


      <ToastContainer />
      <section className='vertical-center'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-12'>

              {/* <i class="fa fa-bath" aria-hidden="true"></i>  */}
              <img src={networkLogo} className='d-flex m-auto' />
              <h1 className='mt-4'> <i class="fa fa-bath" aria-hidden="true"></i> UTC Chain Faucet</h1>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-8 offset-lg-2">
              <div className="input-group ethInput">
                <input type="text" onChange={(e) => _setaddress(e)} className="form-control" placeholder="UTC address..." />
                <div class="input-group-append">
                  <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Give me UTC</button>
                  <div className="dropdown-menu dropdown-menu-right">
                    <button type='button' className="dropdown-item" onClick={() => requestAddress()}>{process.env.REACT_APP_Testnet} UTC / 1 day</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-6 offset-lg-3'>
              <div className="panel panel-small panel-default requestsTable_box">
                <div className="panel-body">
                  <table className="table table-condensed requestsTable">
                    <tbody>
                      {responseaddress?.map((card, index) => {
                        return (<tr key={index} style={{ display: card ? 'block' : 'none' }} id={card.address}>

                          <td><span className='requestsTable_addr'><pre>{card.Address}</pre></span></td>
                          <td className='text-center'><span>{card._status}</span>
                            <div className="progress">
                              <div id={card.Address} className={card._status === "Funded" ? "progress-bar progress-bar-striped progress-bar-animated bg-success " : "progress-bar progress-bar-striped progress-bar-animated bg-danger"}></div>
                              {/* <div id={card.Address} className="progress-bar progress-bar-striped progress-bar-animated" ></div> */}
                            </div>
                          </td>
                        </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
                <div className="panel-footer">
                  <table>
                    <tbody>
                      <tr>

                        <td><i className="fa fa-heartbeat" aria-hidden="true"></i> <span id="funds">0.5</span> UTC</td>
                        <td><i className="fa fa-university" aria-hidden="true"></i> <span id="funded">{FoundedCount}</span> funded</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <p>You can track the current pending requests below the input field to see how much you have to wait until your turn comes.</p>
            <p><i>The UTC is running invisible reCaptcha protection against bots.</i></p>
          </div>
        </div>
      </section>
      {siteKey && (
        <GoogleReCaptchaProvider reCaptchaKey={siteKey} language="en">
          <GoogleReCaptcha
            onVerify={handleVerify}
          />
        </GoogleReCaptchaProvider>
      )}
    </>
  );
}

export default App;
